













import { Component, Vue } from 'vue-property-decorator';
import { event } from 'vue-analytics';
import { State, Action } from 'vuex-class';
import SLayoutAuthentication
  from '@stratumfive/ui-baseplate/src/components/SLayoutAuthentication/SLayoutAuthentication.vue';
import SLoginForm from '@stratumfive/ui-baseplate/src/components/SLoginForm/SLoginForm.vue';

@Component({
  components: {
    SLayoutAuthentication,
    SLoginForm,
  },
  metaInfo() {
    return {
      title: 'Login',
    };
  },
})
export default class Login extends Vue {
  @State((state) => state.user.error)
  private error;

  @State((state) => state.user.token)
  private token;

  @Action('login') private login;

  private username;

  private password;

  private showErrors;

  private loading: boolean;

  constructor() {
    super();
    this.username = '';
    this.password = '';
    this.showErrors = false;
    this.loading = false;
  }

  private attemptLogin({ username, password }) {
    this.loading = true;
    this.login({ username: username.trim(), password: password.trim() })
      .then(() => {
        this.loading = false;
        event('auth', 'login', 'success');
        this.$router.push({ name: 'home' });
      })
      .catch((error) => {
        event('auth', 'login', 'error');
        this.loading = false;
        this.showErrors = true;
      });
  }

  private get userError() {
    return !!Object.keys(this.error).length;
  }

  private get errorMessage() {
    if (!this.showErrors) {
      return '';
    }

    return this.error;
  }
}
